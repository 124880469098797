import React, { useEffect, useState, useMemo, useRef } from 'react';
import { Toast, Modal,Carousel } from 'antd-mobile';
import { useHistory, useLocation } from 'react-router-dom';
import invariant from 'invariant';
import dayjs, { Dayjs } from 'dayjs';
import { qs } from '@/common/request';
import { getCardInfo, ICardInfo } from './api';
import { RESPONSE_CODE } from '@/common/config';
import { img_home_banner, icon_home_chongzhi, icon_home_peiwang, icon_home_laba, icon_scan,icon_qrcode_scan } from '@/assets/index';
import Card from '@/component/card';
import './index.less';

const cssPreFix = 'index-container';

/** 卡片状态,索引对应 */
const titleList = ['正常', '预销号', '销号', '销号', '正常', '正常'];

export interface IIotCardInfo {
  title: string;
  value: string | undefined;
  icon?: string;
  className?: string;
  itemStyle?: any;
}

const Index: React.FC = () => {
  const [data, setData] = useState<ICardInfo>({} as any);
  const location = useLocation();
  const history = useHistory();
  let { sn: snCode } = qs.parse(location.search);
  let rechargeMessage = useRef('');
  const [visible, setvisible] = useState(false)
  useEffect(() => {
    document.title = '欢迎使用升腾云喇叭';
    if (snCode) {
      getData();
    } else {
      inputSnModal();
    }
  }, []);

  const inputSnModal = () => {
    const onPress = async (value: string) => {
      if (!value) {
        Toast.info('请输入设备sn号', 2);
        throw new Error();
      }
      snCode = value;
      await getData({
        handleError: (error) => {
          throw error;
        },
      });
    };
    Modal.prompt(
      '请输入设备sn号',
      null,
      [
        {
          text: '确定',
          onPress,
        },
      ],
      'default',
      '',
      ['请输入设备sn号']
    );
  };

  const getData = async (params?: { handleError: (error: Error) => void }) => {
    try {
      Toast.loading('loading...', 0);
      const result = await getCardInfo({ snCode });
      invariant(result.code === RESPONSE_CODE.success, result?.msg || '请求失败');
      invariant(result.data.cardNo, '未查询到该设备的物联卡信息');
      setData(result.data);
      Toast.hide();
      if (result.msg) {
        rechargeMessage.current = decodeURI(result.msg);
      }
    } catch (error: any) {
      Toast.info(error.message || '请求失败,请重试');
      params?.handleError?.(error);
    }
  };

  const cardList = useMemo(() => {
    return [
      {
        title: (
          <React.Fragment>
            <img className={`icon`} src={icon_home_laba} />
            云喇叭
          </React.Fragment>
        ),
        dataIndex: 'snCode',
        render: (value: string) => snCode || value,
        className: 'header',
      },
      {
        title: '流量卡号',
        dataIndex: 'cardNo',
      },
      {
        title: '流量卡状态',
        dataIndex: 'status',
        render: (value: number) => titleList[value],
      },
      // {
      //   title: '流量卡有效期',
      //   dataIndex: 'contractDate',
      //   render: (val: string) => {
      //     let time: Dayjs | undefined | string = dayjs(new Date(val));
      //     if (!time.isValid()) {
      //       time = undefined;
      //     } else {
      //       time = time?.format('YYYY-MM-DD');
      //     }
      //     return time;
      //   },
      // },
    ];
  }, []);

  // ==== 可操作列表项 =====
  const actions = [
    {
      icon: icon_home_chongzhi,
      title: '流量卡充值',
      onClick: () => {
        try {
          invariant((!!snCode || !!data?.snCode) && !!data?.cardNo && !!data?.qrId, '该卡暂不支持充值业务');
          invariant(data?.isIntercept !== '0', data?.msg ?? '该卡暂不支持充值业务');
          history.push(
            `/recharge?cardNo=${data?.cardNo}&qrId=${data?.qrId}&msg=${rechargeMessage.current}&importantFields=cardNo,phone`
          );
        } catch (error: any) {
          Toast.info(error.message);
        }
      },
    },
    {
      icon: icon_home_peiwang,
      title: '云喇叭配网',
      onClick: () => {
        window.location.href = `http://wx2.iot.51cpay.com/index2.html`;
      },
    },
    // {
    //   icon: icon_home_shuomingshu,
    //   title: '云喇叭说明书',
    //   onClick: () => {},
    // },
  ];

  const renderActionsRow = (item: any) => {
    const actionsCssPre = `${cssPreFix}-actions`;
    return (
      <div className={`${actionsCssPre}-row`} key={item.title} onClick={item.onClick}>
        <img className={`${actionsCssPre}-icon`} src={item.icon} />
        <div className={`${actionsCssPre}-title`}>{item.title}</div>
      </div>
    );
  };
  return (
    <div className={cssPreFix}>
      {/* <img className={`${cssPreFix}-banner`} src={img_home_banner} /> */}
      <Carousel
        autoplay={true}
        infinite
      >
        {/* <img className={`${cssPreFix}-banner`} src={icon_scan} onClick={() => {setvisible(true)}} />*/}
        <img className={`${cssPreFix}-banner`} src={img_home_banner} />

      </Carousel>      
      <Card list={cardList} data={data} data-bg={titleList[data?.status]} />
      <div className={`${cssPreFix}-actions`}>{actions.map((item) => renderActionsRow(item))}</div>
      <Modal
          visible={visible}
          transparent
          maskClosable={false}
          onClose={() => {setvisible(false)}}
          title="长按识别二维码"
          footer={[{ text: '关闭', onPress: () => { setvisible(false); } }]}
        >
            <img className={`${cssPreFix}-scan`} src={icon_qrcode_scan} />
        </Modal>
    </div>
  );
};

export default Index;
